.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.mdt-intervaltimerunlimitedapp-container {
    max-width: 800px;
    margin: 0px;
    padding: 20px;
    background-color: #EEECE6;
}

.mdt-screenshot-container {
    height: flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: var(--foreground);
    padding: 0px 10px;
}

.mdt-screenshot-container .screenshot {
    width: 340px;
    max-width: 90%;
    height: auto;
    margin-bottom: 30px;
}

h2 {
    text-align: left;
}

h3 {
    text-align: center;
    text-decoration: underline;
}