body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    color: var(--link);
    text-decoration: none;
}

a:hover {
    color: var(--link-hover);
}

:root {
    --header-height: 80px;
    --footer-height: 80px;

    --accent: #9254e2;
    --background: #EEECE6;
    --header-background: #000000;
    --footer-background: #000000;

    --foreground: #08050d;
    --divider: #2d282d;

    --link: #0066CC;
    --link-hover: #3399FF;
}