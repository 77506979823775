.mdt-footer-container {
    height: var(--footer-height);
    background-color: var(--footer-background);
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    padding: 20px;
}

a {
    color: #ffffff;
    text-decoration: none;
}

a:hover {
    color: #9378FF;
    text-decoration: none;
}