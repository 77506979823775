.mdt-header-container {
    height: var(--header-height);
    background-color: var(--header-background);
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--foreground);
    padding: 0px 10px;
}

.mdt-header-container .logo {
    width: calc(var(--header-height) - 10px);
    height: calc(var(--header-height) - 10px);
    margin-right: 8px;
}

.menuItem {
    padding: 0px 20px;
}

a {
    color: #ffffff;
    text-decoration: none;
}

a:hover {
    color: #9378FF;
    text-decoration: none;
}