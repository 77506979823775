.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.mdt-privacypolicy-container {
    max-width: 800px;
    margin: 0px;
    padding: 20px;
    background-color: #EEECE6;
}

ul li {
    padding: 5px 0px;
}

h1 {
    text-align: left;
}

p {
    text-align: left;
}

li {
    text-align: left;
}