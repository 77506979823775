.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.mdt-contact-container {
    max-width: 800px;
    margin: 0px;
    padding: 20px;
    background-color: #EEECE6;
}

.link-style {
    color: #0066CC;
}

.link-style:hover {
    color: #3399FF;
}

p {
    text-align: left;
}